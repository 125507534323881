import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import Navigation from '../components/Navigation';
import Hero from '../sections/boostAmazonSalesRank/Hero';
import HeroSub from '../sections/boostAmazonSalesRank/HeroSub';
import FeaturesMain from '../sections/FeaturesMain';
import Features from '../sections/Features';
import CtaBanner from '../sections/CtaBanner';
import Footer from '../sections/Footer';

import faviconPath from '../images/favicon.ico';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0 0 0;
`;

const BoostAmazonSalesRank = () => (
  <Container>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Boost Amazon Sales Rank</title>
      <link rel="canonical" href="https://bidmatik.com/boost-amazon-sales-rank" />
      <link rel="shortcut icon" type="image/png" href={faviconPath} />
    </Helmet>
    <Navigation />
    <Hero />
    <HeroSub />
    <FeaturesMain />
    <Features />
    <CtaBanner />
    <Footer />
  </Container>
);

export default BoostAmazonSalesRank;
