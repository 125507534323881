import React from 'react';
import styled from 'styled-components';

import WidthWrapper from '../../components/WidthWrapper';
import Button from '../../components/Button';
import ExternalLink from '../../components/ExternalLink';
import turboImagePath from '../../images/turbo.svg';

const Container = styled.div`
  display: flex;
  @media screen and (min-width: 900px) {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 121px;
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
    margin-top: 52px;
    align-items: center;
  }
`;

const Turbo = styled.img`
  @media screen and (max-width: 900px) {
    display: none;
  }
  @media screen and (min-width: 900px) {
    max-height: 287px;
    max-width: 287px
  }
  user-select: none;
`;

const Heading = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 59px;
  color: rgba(0, 0, 0, 0.870588);\
  max-width: 340px;
`;

const SignupButton = styled(Button)`
  background: linear-gradient(
    137.8deg,
    #0051f0 0%,
    #00c9f0 99.99%,
    #0051f0 100%
  );
  width: 160px;
  border-radius: 4px;
  text-align: center;
  @media screen and (max-width: 900px) {
    display: none;
  }
  @media screen and (min-width: 900px) {
    margin-top: 38px
  }
`;

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 900px) {
    order: 1;
    margin-top: 24px;
    text-align: center;
    align-items: center;
  }
  @media screen and (min-width: 900px) {
    margin-top: 21px;
  }
`;

const Hero = () => (
  <WidthWrapper>
    <Container>
      <HeadingWrapper>
        <Heading>Boost Amazon Sales Rank</Heading>
        <ExternalLink href="https://calendly.com/lars_bidmatik/bidmatik-product-demo?month=2019-07">
          <SignupButton>Optimize Ads Now</SignupButton>
        </ExternalLink>
      </HeadingWrapper>
      <Turbo src={turboImagePath} />
    </Container>
  </WidthWrapper>
);

export default Hero;
