/* eslint-disable */
import React from 'react';
import styled from 'styled-components';

import WidthWrapper from '../../components/WidthWrapper';
import Button from '../../components/Button';
import ExternalLink from '../../components/ExternalLink';
import heroBackgroundImagePath from '../../images/heroBackground.svg';
import mockupImagePath from '../../images/mockup.png';
import MinorFeatures from './MinorFeatures';

const Container = styled.div`
  background: linear-gradient(120.37deg, #FF9805 0%, #F64C55 100%);
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 900px) {
    padding-bottom: 10px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    margin-top: -183px;
    z-index: 2;
  }
  @media screen and (min-width: 900px) {
    flex-direction: row;
    margin-top: -93px;
    z-index: 2;
  }
`;

const WhiteBackground = styled.div`
  background-image: url(${heroBackgroundImagePath});
  width: 120%;
  height: 300px;
  transform: rotate(-5deg) translate(-2%,-97%) scaleY(2.2);
`;

const MockupImage = styled.img`
  @media screen and (min-width: 900px) {
    width: 580px;
    height: 273px;
  }
  @media screen and (max-width: 900px) {
    height: auto;
    width: 100%;
  }
`;

const WidthWrapperStyled = styled(WidthWrapper)`
  z-index: 2;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 900px) {
    margin-top: 25px;
    margin-left: 32px;
  }
  @media screen and (max-width: 900px) {
    margin-top: 68px;
    padding: 0 20px 0 20px;
  }
`;

const SmallHeading = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  text-transform: uppercase;
`;

const Heading = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  color: #FFFFFF;
  max-width: 246px;
`;

const SubHeading = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  max-width: 230px;
  list-style-type: circle;
  margin-top: 12px;

  ul {
    @media screen and (max-width: 900px) {
      padding-inline-start: 15px;
    }
  }

  li {
    margin-top: 10px;
  }
`;

const SignupButton = styled(Button)`
  background: linear-gradient(
    137.8deg,
    #0051f0 0%,
    #00c9f0 99.99%,
    #0051f0 100%
  );
  width: 160px;
  border-radius: 4px;
  text-align: center;
  align-self: center;
  margin: 30px auto 30px auto;
`;

const HideOnDesktop = styled.div`
  @media screen and (min-width: 900px) {
    display: none;
  }
`;

const HeroSub = () => (
  <Container>
    <WhiteBackground />
    <WidthWrapperStyled>
      <ContentWrapper>
        <MockupImage src={mockupImagePath} />
        <TextWrapper>
          <SmallHeading>
            Intelligent Automation Tool
          </SmallHeading>
          <Heading>
            Automate your ad campaigns
          </Heading>
          <SubHeading>
            Set campaign optimization on autopilot for optimal performance
            <ul>
              <li>Optimise existing campaigns</li>
              <li>Enable auto optimisation in one click</li>
              <li>Share insights easily with reports</li>
            </ul>
          </SubHeading>
        </TextWrapper>
      </ContentWrapper>
        <HideOnDesktop>
          <ExternalLink href="https://calendly.com/lars_bidmatik/bidmatik-product-demo?month=2019-07">
            <SignupButton>
              Schedule your Demo
            </SignupButton>
          </ExternalLink>
      </HideOnDesktop>
      <MinorFeatures/>
    </WidthWrapperStyled>
  </Container>
);

export default HeroSub;
