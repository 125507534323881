import React from 'react';
import styled from 'styled-components';

import WidthWrapper from '../../components/WidthWrapper';

import TouchIconPath from '../../images/icons/touch.svg';
import WheelRightIconPath from '../../images/icons/wheelRight.svg';
import PeopleIconPath from '../../images/icons/people.svg';


const Container = styled.div`
  margin-top: 100px;
  margin-bottom: 80px;
  color: #fff;
`;

const Heading = styled.div`
  font-family: Roboto;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  color: #FFFFFF;
  margin-bottom: 58px;
`;

const SmallHeading = styled.div`
  font-family: Roboto;
  font-style: bold;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  align-items: center;
  display: flex;
`;

const FeaturesColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FeaturesRow = styled.div`
  @media screen and (min-width: 900px) {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
  }
`;

const Feature = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const FeatureText = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135.7%;
  max-width: 228px;
  margin-left: 35px;
  margin-top: 5px;
`;

const Icon = styled.img`
  width: 22px;
  height: 22px;
  margin-right: 10px;
`;

const MinorFeatures = () => (
  <WidthWrapper>
    <Container>
      <Heading>Boost your sales rank with optimal bidding</Heading>
      <FeaturesRow>
        <FeaturesColumn>
          <Feature>
            <SmallHeading>
              <Icon src={TouchIconPath} />
              Boost sales rank
            </SmallHeading>
            <FeatureText>
              Use intelligent ad automation to catapult your product to the top of search results.
            </FeatureText>
          </Feature>
        </FeaturesColumn>
        <FeaturesColumn>
          <Feature>
            <SmallHeading>
              <Icon src={WheelRightIconPath} />
              Start your flywheel effect
            </SmallHeading>
            <FeatureText>
              Reinvest your profit in effective advertising to start your revenue flywheel.
            </FeatureText>
          </Feature>
        </FeaturesColumn>
        <FeaturesColumn>
          <Feature>
            <SmallHeading>
              <Icon src={PeopleIconPath} />
              Earn user reviews
            </SmallHeading>
            <FeatureText>
              Auto focus on relevant keywords to get your product in front of the right people
            </FeatureText>
          </Feature>
        </FeaturesColumn>
      </FeaturesRow>
    </Container>
  </WidthWrapper>
);

export default MinorFeatures;
